import options from './constants';
export var productTypes = [
    {
        label: 'TectoroEMM',
        value: 'tectoroEMM'
    }
];
export var planTypes = [
    {
        label: 'Standard Plan',
        value: 'STANDARD'
    }
];
export var deviceSchema = [
    {
        label: 'No.of Devies Per User',
        helpText: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual ',
        value: 'noOfDevices',
        type: 'text',
        inputType: 'input'
    },
    {
        label: 'Platform',
        value: 'platform',
        inputType: 'radio',
        helpText: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual ',
        type: 'radio',
        radios: [
            {
                label: 'Any Platform',
                value: 'any',
                helpText: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual '
            },
            {
                label: 'Specific Platform',
                value: 'specific',
                helpText: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual '
            }
        ]
    },
    {
        label: 'Android',
        value: 'android',
        inputType: 'switch',
        helpText: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual '
    },
    {
        value: 'androidInput',
        type: 'text',
        placeHolder: 'android',
        inputType: 'input',
        showOn: ['android']
    },
    {
        label: 'Windows',
        value: 'windows',
        inputType: 'switch',
        helpText: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual '
    },
    {
        value: 'windowsInput',
        placeHolder: 'windows',
        type: 'text',
        inputType: 'input',
        showOn: ['windows']
    }
];
export var LicenseSchemaaa = [
    {
        label: 'Product Type',
        helpText: 'Select the type of product for which licenses are to be issued',
        value: 'productType',
        type: 'text',
        inputType: 'select',
        disableWhileEdit: true,
        xl: '6',
        lg: '6',
        md: '6',
        options: productTypes,
        mandatory: true
    },
    {
        label: 'Plan Type',
        helpText: 'Choose the appropriate plan type for assigning licenses to the customer',
        value: 'planType',
        type: 'text',
        inputType: 'select',
        disableWhileEdit: true,
        xl: '6',
        lg: '6',
        md: '6',
        options: planTypes,
        mandatory: true
    },
    {
        label: 'Start Date',
        type: 'date',
        value: 'startDate',
        ph: 'Select the date on which license is issued to customer',
        inputType: 'date',
        disableWhileEdit: true,
        minDate: 'today',
        hideMinDateWhileEdit: true,
        dateRange: true,
        xl: '6',
        lg: '6',
        md: '6',
        mandatory: true
    },
    {
        label: 'End Date',
        type: 'date',
        value: 'endDate',
        ph: 'Select the date on which license issued to the customer expires',
        inputType: 'date',
        minDate: 'startDate',
        checkIn: ['validation', 'values'],
        dateRange: true,
        xl: '6',
        lg: '6',
        md: '6',
        mandatory: true
    },
    {
        label: 'No. of Devices',
        helpText: 'Enter the total number of devices issued to the license',
        value: 'noOfLicenses',
        type: 'text',
        inputType: 'input',
        mandatory: true
    },
    {
        label: 'Tenure',
        helpText: 'View the duration of your licenses to track usage and ensure timely renewals',
        value: 'tenure',
        type: 'text',
        inputType: 'input',
        disabled: true
    },
    {
        label: 'Note',
        helpText: 'Add any notes for future references',
        value: 'note',
        type: 'textarea',
        disableWhileEdit: true,
        inputType: 'input'
    }
];
export var tenantSchemas = {
    DEVICE_POLICY: [
        { label: 'Android Policy', show: [{ key: 'androidEnterpriseEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Configurations',
            value: 'AE_CONFIGURATIONS',
            key: 'configurations',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            helpText: 'Select the management set for creating policies',
            inputType: 'select',
            isMulti: true,
            options: options.AE_CONFIGURATIONS
        },
        {
            label: 'Policy Types',
            value: 'AE_POLICY_TYPES',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            key: 'policy_types',
            inputType: 'select',
            mandatory: true,
            helpText: 'Select Android policy types for the portal',
            isMulti: true,
            conditionalMandatory: true,
            mandatoryCondition: ['androidEnterpriseEnabled', 'DEVICE_POLICY'],
            // hide: 'EMM_ENABLED',
            options: options.AE_POL_TYPES
        },
        {
            label: 'Dedicated device Policy Types',
            value: 'AE_SUB_POLICY_TYPES',
            key: 'dd_policy_types',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            showOn: 'AE_POLICY_TYPES',
            showVal: 'DD',
            inputType: 'select',
            helpText: 'Choose which modes are to be activated in dedicated device management set',
            mandatory: true,
            isMulti: true,
            hide: 'AE_POLICY_TYPES',
            checkInArr: true,
            checkVal: 'DD',
            options: options.AE_SUB_POL_TYPES
        },
        {
            label: 'Workflow Required',
            value: 'AE_WORKFLOW',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            helpText: 'ontrols Admin access to enable workflow for Android policy actions, including policy creation and approval',
            inputType: 'switch'
        },
        {
            label: 'Enable Launcher Branding',
            value: 'AE_LAUNCHER_BRANDING',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            helpText: 'Controls Admin access to allow custom launcher branding and activate the branding tab in launcher settings',
            inputType: 'switch'
        },
        {
            label: 'Package Enabling',
            value: 'AE_PACKAGE_ENABLED',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            helpText: 'Controls Admin access to enable or disable packages to activate the Packages tab in the policy screen.',
            inputType: 'switch'
        },
        { label: 'AndroidTV Policy', show: [{ key: 'androidTvEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Geofence',
            value: 'TV_GEOFENCE',
            show: [{ key: 'androidTvEnabled', value: true }],
            helpText: 'Controls Admin access to enable geofencing on Android TV',
            inputType: 'switch'
        },
        { label: 'Apple Policy', show: [{ key: 'appleIOSEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Policy Types',
            value: 'IOS_POLICY_TYPES',
            show: [{ key: 'appleIOSEnabled', value: true }],
            key: 'policy_types',
            mandatory: true,
            inputType: 'select',
            helpText: 'Controls Admin access to create iOS and macOS policies',
            isMulti: true,
            conditionalMandatory: true,
            mandatoryCondition: ['appleIOSEnabled', 'DEVICE_POLICY'],
            // hide: 'EMM_ENABLED',
            options: options.IOS_POL_TYPES
        },
        {
            label: 'iOS Policy Types',
            value: 'IOS_SUB_POLICY_TYPES',
            show: [{ key: 'appleIOSEnabled', value: true }],
            showOn: 'IOS_POLICY_TYPES',
            showVal: 'ios',
            inputType: 'select',
            helpText: 'Controls Admin access in selecting policy types like Single App or Multi App to apply tailored management settings',
            mandatory: true,
            isMulti: true,
            options: options.IOS_SUB_POL_TYPES
        },
        { label: 'Windows Policy', show: [{ key: 'windowsEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Policy Types',
            value: 'WINDOWS_POLICY_TYPES',
            show: [{ key: 'windowsEnabled', value: true }],
            key: 'policy_types',
            inputType: 'select',
            mandatory: true,
            helpText: 'Controls Admin access to select policy types for applying customized management settings on devices',
            isMulti: true,
            conditionalMandatory: true,
            mandatoryCondition: ['windowsEnabled', 'DEVICE_POLICY'],
            options: options.WINDOWS_POLICY_TYPES
        }
    ],
    DEVICES: [
        { label: 'Android Devices', show: [{ key: 'androidEnterpriseEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Maximum device',
            value: 'MAX_ANDROID_DEVICE',
            inputType: 'input',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            helpText: 'Specify the count of maximum number of records displayed for enrolled devices in device inventory table',
            maxLength: 8,
            conditionalMandatory: true,
            mandatoryCondition: ['androidEnterpriseEnabled', 'DEVICES']
        },
        {
            label: 'Filter Panels',
            value: 'AE_FILTER_PANELS',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            key: 'filter_panels',
            inputType: 'select',
            helpText: 'Choose the filters to be displayed on the device inventory page for Android devices',
            isMulti: true,
            options: options.AE_FILTER_PANELS
        },
        {
            label: 'Table Columns',
            value: 'AE_TABLE_COLUMNS',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            mandatory: true,
            key: 'table_columns',
            checkObject: true,
            helpText: 'Select the columns to be displayed in the device inventory table',
            inputType: 'select',
            isMulti: true,
            options: options.AE_TABLE_COLUMNS
        },
        {
            label: 'Device Actions & Monitoring Items',
            value: 'AE_ACTIONS',
            mandatory: true,
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            key: 'device_actions',
            helpText: 'Select the actions and monitoring features for to be displayed in Android devices',
            inputType: 'select',
            isMulti: true,
            options: options.AE_ACTIONS
        },
        {
            label: 'Enable Data Tracking',
            value: 'AE_DATA_TRACKING',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            helpText: 'Activate data usage tracking for all enrolled Android devices',
            inputType: 'switch'
        },
        {
            label: 'Show SIM Info',
            value: 'SIM_INFO',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            helpText: 'Enable this to display detailed SIM card information for all Android devices in metrics',
            inputType: 'switch'
        },
        {
            label: 'Hourly App Usage',
            value: 'AE_HOURLY_APP_USAGE',
            helpText: 'Enable this to receive hourly app usage status on Android devices',
            inputType: 'switch',
            show: [{ key: 'androidEnterpriseEnabled', value: true }]
        },
        { label: 'AndroidTV Devices', show: [{ key: 'androidTvEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Device Actions & Monitoring Items',
            value: 'TV_ACTIONS',
            mandatory: true,
            show: [{ key: 'androidTvEnabled', value: true }],
            key: 'device_actions',
            helpText: 'Select the actions and monitoring features for to be displayed in Android TV devices',
            inputType: 'select',
            isMulti: true,
            options: options.TV_ACTIONS
        },
        {
            label: 'Hourly App Usage',
            value: 'TV_HOURLY_APP_USAGE',
            show: [{ key: 'androidTvEnabled', value: true }],
            helpText: 'Enable this to receive hourly app usage status on Android TV devices',
            inputType: 'switch'
        },
        { label: 'Apple Devices', show: [{ key: 'appleIOSEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Maximum device',
            value: 'MAX_IOS_DEVICE',
            show: [{ key: 'appleIOSEnabled', value: true }],
            inputType: 'input',
            helpText: 'Specify the count of maximum number of records displayed for enrolled devices in device inventory table',
            maxLength: 8,
            conditionalMandatory: true,
            mandatoryCondition: ['appleIOSEnabled', 'DEVICES']
        },
        {
            label: 'Device Actions & Monitoring Items',
            value: 'IOS_ACTIONS',
            mandatory: true,
            show: [{ key: 'appleIOSEnabled', value: true }],
            key: 'ios_device_actions',
            helpText: 'Select the actions and monitoring features for to be displayed in iOS and macOS devices',
            inputType: 'select',
            isMulti: true,
            options: options.IOS_ACTIONS
        },
        {
            label: 'Filter Panels',
            value: 'IOS_FILTER_PANELS',
            show: [{ key: 'appleIOSEnabled', value: true }],
            key: 'filter_panels',
            inputType: 'select',
            helpText: 'Choose the filters to be displayed on the device inventory page for Apple devices',
            isMulti: true,
            options: options.IOS_FILTER_PANELS
        },
        { label: 'Windows Devices', show: [{ key: 'windowsEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Maximum device',
            value: 'MAX_WINDOWS_DEVICES',
            show: [{ key: 'windowsEnabled', value: true }],
            inputType: 'input',
            change: 'max_device_page_change',
            helpText: 'Specify the count of maximum number of records displayed for enrolled devices in device inventory table',
            maxLength: 8,
            conditionalMandatory: true,
            mandatoryCondition: ['windowsEnabled', 'DEVICES']
        },
        {
            label: 'Device Actions & Monitoring Items',
            value: 'WINDOWS_ACTIONS',
            mandatory: true,
            show: [{ key: 'windowsEnabled', value: true }],
            helpText: 'Select the actions and monitoring features for to be displayed in windows devices',
            inputType: 'select',
            isMulti: true,
            options: options.WINDOWS_ACTIONS
        },
        {
            label: 'Filter Panels',
            value: 'WINDOWS_FILTER_PANELS',
            show: [{ key: 'windowsEnabled', value: true }],
            key: 'filter_panels',
            inputType: 'select',
            helpText: 'Choose the filters to display on the Windows device inventory page for windows devices',
            isMulti: true,
            options: options.WINDOWS_FILTER_PANEL
        }
    ],
    DASHBOARD: [
        { label: 'Android Dashboard', show: [{ key: 'androidEnterpriseEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Widgets',
            value: 'DASHBOARD_WIDGETS',
            mandatory: true,
            helpText: 'Select the widgets to display on the Android dashboard.',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            inputType: 'select',
            key: 'widgets',
            conditionalMandatory: true,
            mandatoryCondition: ['DASHBOARD'],
            isMulti: true,
            options: options.AE_DASHBOARD_WIDGETS
        },
        {
            label: 'Custom dashboard user',
            value: 'CUSTOM_DASHBOARD_EMAIL',
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            helpText: 'Enter the email addresses of the users for whom the custom dashboard should be enabled.',
            inputType: 'input',
            multiple: true
        },
        { label: 'AndroidTV Dashboard', show: [{ key: 'androidTvEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Widgets',
            value: 'TV_WIDGETS',
            helpText: 'Select the widgets to display on the Android TV dashboard.',
            show: [{ key: 'androidTvEnabled', value: true }],
            inputType: 'select',
            key: 'widgets',
            mandatory: true,
            conditionalMandatory: true,
            mandatoryCondition: ['DASHBOARD'],
            isMulti: true,
            options: options.TV_WIDGETS
        },
        { label: 'Apple Dashboard', show: [{ key: 'appleIOSEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Widgets',
            value: 'IOS_WIDGETS',
            helpText: 'Select the widgets to display on the Apple dashboard.',
            show: [{ key: 'appleIOSEnabled', value: true }],
            inputType: 'select',
            key: 'widgets',
            mandatory: true,
            conditionalMandatory: true,
            mandatoryCondition: ['DASHBOARD'],
            isMulti: true,
            options: options.IOS_WIDGETS
        },
        { label: 'Windows Dashboard', show: [{ key: 'windowsEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Widgets',
            value: 'WINDOWS_WIDGETS',
            helpText: 'Select the widgets to display on the Windows dashboard.',
            show: [{ key: 'windowsEnabled', value: true }],
            inputType: 'select',
            key: 'widgets',
            mandatory: true,
            conditionalMandatory: true,
            mandatoryCondition: ['DASHBOARD'],
            isMulti: true,
            options: options.WINDOWS_WIDGETS
        }
    ],
    REPORTS: [
        { label: 'Android Reports', show: [{ key: 'androidEnterpriseEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Reports',
            value: 'ANDROID_REPORTS',
            helpText: 'Select which reports are to be displayed to portal  users for enrolled Android devices',
            inputType: 'select',
            mandatory: true,
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            conditionalMandatory: true,
            mandatoryCondition: ['REPORTS', 'androidEnterpriseEnabled'],
            isMulti: true,
            options: options.AE_REPORTS
        },
        { label: 'TV Reports', show: [{ key: 'androidTvEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Reports',
            value: 'TV_REPORTS',
            helpText: 'Select which reports are to be displayed to portal  users for enrolled Android TV devices',
            inputType: 'select',
            show: [{ key: 'androidTvEnabled', value: true }],
            isMulti: true,
            mandatory: true,
            conditionalMandatory: true,
            mandatoryCondition: ['REPORTS', 'androidTvEnabled'],
            options: options.TV_REPORTS
        }
    ],
    BULK_REQUESTS: [
        { label: 'Android Bulk Requests', show: [{ key: 'androidEnterpriseEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Bulk Request Types',
            value: 'AE_BULK_REQUEST_TYPES',
            helpText: 'Select what options are to be made available in the Bulk Requests option for Android devices',
            conditionalMandatory: true,
            mandatory: true,
            show: [{ key: 'androidEnterpriseEnabled', value: true }],
            isMulti: true,
            mandatoryCondition: ['BULK_REQUEST', 'androidEnterpriseEnabled'],
            inputType: 'select',
            options: options.AE_BULK_REQUESTS
        },
        { label: 'TV Bulk Requests', show: [{ key: 'androidTvEnabled', value: true }], inputType: 'heading' },
        {
            label: 'Bulk Request Types',
            isMulti: true,
            mandatory: true,
            value: 'TV_BULK_REQUEST_TYPES',
            helpText: 'Select what options are to be made available in the Bulk Requests option for Android TV devices',
            conditionalMandatory: true,
            show: [{ key: 'androidTvEnabled', value: true }],
            mandatoryCondition: ['BULK_REQUEST', 'androidTvEnabled'],
            inputType: 'select',
            options: options.TV_BULK_REQUEST_TYPES
        }
    ],
    MESSAGES: [
        {
            label: 'Schedule Messages',
            value: 'SCHEDULE_MESSAGE',
            inputType: 'switch',
            helpText: 'Controls Admin access to schedule a message to the enrolled devices',
            show: [{ key: 'MESSAGES', value: true }]
        },
        {
            label: 'Recall',
            value: 'RECALL',
            inputType: 'switch',
            helpText: 'Controls Admin access to recall any previously sent message',
            show: [{ key: 'MESSAGES', value: true }]
        }
    ],
    ADMIN_REPORT: [
        {
            label: 'Reports',
            value: 'ADMIN_REPORTS',
            helpText: 'Select which reports are to be displayed to portal  users under Admin section',
            inputType: 'select',
            conditionalMandatory: true,
            mandatory: true,
            isMulti: true,
            mandatoryCondition: ['ADMIN_REPORT'],
            options: options.ADMIN_REPORTS
        }
    ]
};
