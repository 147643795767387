var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DeleteModal from '../../Components/Common/DeleteModal';
import Loader from '../../Components/Common/Loader';
import OffcanvasModal from '../../Components/Common/OffcanvasModal';
import TableContainer from '../../Components/Common/TableContainer';
import { toastMessages } from '../../Components/Common/ToastMessages';
import { AuthUser, convertUTCtoIST, envVariables, privileges as privs } from '../../Components/Common/Util';
import { COUNTRIES } from '../../Components/Common/constants/Countries';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import { usersSchema } from '../../common/constants/Constants';
import AddUsers from './AddUsers';
var Users = function () {
    var _a, _b, _c;
    var api = new APIClient();
    document.title = 'Users';
    var envConf = envVariables();
    var _d = useState(false), offCanvasModel = _d[0], setOffCanvasModel = _d[1];
    var _e = useState(), currentDetailsObj = _e[0], setCurrentDetailsObj = _e[1];
    var _f = useState({}), selectedRow = _f[0], setSelectedRow = _f[1];
    var _g = useState({}), errors = _g[0], setErrors = _g[1];
    var _h = useState(''), roleType = _h[0], setRoleType = _h[1];
    var _j = useState(false), loading = _j[0], setLoading = _j[1];
    var _k = useState(''), mode = _k[0], setMode = _k[1];
    var _l = useState({ page: 1, size: 100 }), searchParams = _l[0], setSearchParams = _l[1];
    var _m = useState([]), users = _m[0], setUsers = _m[1];
    var _o = useState(0), totalRecords = _o[0], setTotalRecords = _o[1];
    var _p = useState([]), schema = _p[0], setSchema = _p[1];
    var _q = useState(false), deleteModal = _q[0], setDeleteModal = _q[1];
    useEffect(function () {
        var _a, _b;
        var user = AuthUser();
        var findPriv = (_b = (_a = user.data) === null || _a === void 0 ? void 0 : _a.privileges) === null || _b === void 0 ? void 0 : _b.includes(privs.SA_USER_EDITOR);
        var schemaArr = JSON.parse(JSON.stringify(usersSchema));
        if (!findPriv)
            schemaArr = schemaArr.filter(function (obj) { return obj.Header !== 'Actions'; });
        setSchema(schemaArr);
        getUsers(searchParams);
    }, []);
    var getUsers = function (params) {
        setLoading(true);
        setSearchParams(params);
        api.get(url.USERS, params, domains.IDM_V1)
            .then(function (resp) {
            resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp === null || resp === void 0 ? void 0 : resp.data) {
                resp.data.forEach(function (ele, index) {
                    var _a, _b, _c, _d, _e;
                    ele.status = ele.status === 'A' ? 'active' : 'inactive';
                    ele.modifiedBy = ele.modifiedby ? ele.modifiedby : 'Tectoro';
                    ele.modifiedDate = ele.modifieddate ? convertUTCtoIST(ele.modifieddate, undefined) : '—';
                    ele.systemUser = (ele === null || ele === void 0 ? void 0 : ele.modifiedby) === 'SYSTEM';
                    ele.name = ele.name ? ele.name : '—';
                    ele.email = ele.email ? ele.email : '—';
                    ele.roleName = ((_a = ele.roledata) === null || _a === void 0 ? void 0 : _a.name) ? (_b = ele.roledata) === null || _b === void 0 ? void 0 : _b.name : '—';
                    ele.countryCode =
                        ((_c = ele.phone) === null || _c === void 0 ? void 0 : _c.length) === 10
                            ? {
                                name: 'India',
                                code: 'IN',
                                emoji: '🇮🇳',
                                unicode: 'U+1F1EE U+1F1F3',
                                image: 'IN.svg',
                                dial_code: '91',
                                disaplay_dial_code: '+91'
                            }
                            : // eslint-disable-next-line max-nested-callbacks
                                COUNTRIES === null || COUNTRIES === void 0 ? void 0 : COUNTRIES.find(function (country) { var _a; return country.dial_code === ((_a = ele.phone) === null || _a === void 0 ? void 0 : _a.slice(0, 2)); });
                    ele.phone = ele.phone ? (((_d = ele.phone) === null || _d === void 0 ? void 0 : _d.length) > 10 ? (_e = ele.phone) === null || _e === void 0 ? void 0 : _e.slice(2) : ele.phone) : '';
                    ele.rowEdit = true;
                    ele.rowDelete = true;
                });
                setUsers(resp.data);
                setTotalRecords(resp === null || resp === void 0 ? void 0 : resp.totalRecords);
            }
            else {
                setUsers([]);
            }
            setLoading(false);
        })
            .catch(function (_err) {
            setLoading(false);
        });
    };
    var handleAddNew = function () {
        setMode('add');
        setOffCanvasModel(true);
        setSelectedRow('');
    };
    var handleEdit = function (row) {
        setMode('edit');
        setOffCanvasModel(true);
        setSelectedRow(row);
    };
    var handleClickView = function (row) {
        setSelectedRow(row);
        setMode('view');
        setOffCanvasModel(true);
    };
    var onStatusChange = function (row) {
        setSelectedRow(row);
        setDeleteModal(true);
    };
    var handleDeleteConfirmation = function () {
        var _a, _b;
        setLoading(true);
        setDeleteModal(false);
        api.update(url.USERS + '/' + ((_a = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _a === void 0 ? void 0 : _a._id), { status: ((_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _b === void 0 ? void 0 : _b.status) === 'active' ? 'I' : 'A' }, domains.IDM_V1)
            .then(function (resp) {
            var _a, _b;
            if (((_a = resp === null || resp === void 0 ? void 0 : resp.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'success') {
                toast.success(((_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _b === void 0 ? void 0 : _b.status) === 'active' ? toastMessages.userDeactivated : toastMessages.userActivated);
                getUsers(searchParams);
            }
        })
            .catch(function (err) {
            setLoading(false);
        });
    };
    var toggle = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (offCanvasModel)
                setOffCanvasModel(false);
            else
                setOffCanvasModel(true);
            return [2 /*return*/];
        });
    }); }, [offCanvasModel]);
    var saveFormData = function (detailsObj, allGroupAccess) {
        var _a, _b, _c;
        detailsObj = __assign(__assign({}, detailsObj), { firstname: (_a = detailsObj === null || detailsObj === void 0 ? void 0 : detailsObj.firstname) === null || _a === void 0 ? void 0 : _a.trim(), lastname: (_b = detailsObj === null || detailsObj === void 0 ? void 0 : detailsObj.lastname) === null || _b === void 0 ? void 0 : _b.trim(), email: (_c = detailsObj === null || detailsObj === void 0 ? void 0 : detailsObj.email) === null || _c === void 0 ? void 0 : _c.trim(), allGroupAccess: allGroupAccess, username: detailsObj === null || detailsObj === void 0 ? void 0 : detailsObj.username, phone: detailsObj === null || detailsObj === void 0 ? void 0 : detailsObj.phone });
        setCurrentDetailsObj(__assign({}, detailsObj));
    };
    var formErrors = function (err) {
        setErrors(__assign({}, err));
    };
    var handleRoleTypeChange = function (option) {
        setRoleType(option);
    };
    var handleOffcanvasBody = function () {
        return (_jsx("div", { className: "position-reltive", children: _jsx(AddUsers, { mode: mode, record: (selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) ? selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original : selectedRow, toggle: toggle, handleSubmit: handleSubmit, saveFormData: saveFormData, formErrors: formErrors, handleRoleTypeChange: handleRoleTypeChange }) }));
    };
    var handleSubmit = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        setLoading(true);
        var params = {
            name: currentDetailsObj.firstname + ' ' + currentDetailsObj.lastname,
            firstname: currentDetailsObj.firstname,
            lastname: currentDetailsObj.lastname,
            email: currentDetailsObj.email,
            role: roleType === null || roleType === void 0 ? void 0 : roleType.value,
            groups: ['*'],
            extra: {},
            phone: (currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.phone) ? ((_a = currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.countryCode) === null || _a === void 0 ? void 0 : _a.dial_code) + (currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.phone) : undefined,
            authType: 'password',
            username: currentDetailsObj.username
        };
        var updateParams = {};
        if (mode === 'edit') {
            updateParams.email = ((_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _b === void 0 ? void 0 : _b.email) === (currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.email) ? undefined : currentDetailsObj.email;
            updateParams.firstname =
                ((_c = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _c === void 0 ? void 0 : _c.firstname) === (currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.firstname) ? undefined : currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.firstname;
            updateParams.name =
                ((_d = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _d === void 0 ? void 0 : _d.firstname) === (currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.firstname) &&
                    ((_e = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _e === void 0 ? void 0 : _e.lastname) === (currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.lastname)
                    ? undefined
                    : currentDetailsObj.firstname + ' ' + currentDetailsObj.lastname;
            updateParams.lastname =
                ((_f = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _f === void 0 ? void 0 : _f.lastname) === (currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.lastname) ? undefined : currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.lastname;
            updateParams.name =
                ((_g = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _g === void 0 ? void 0 : _g.firstname) === (currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.firstname) &&
                    ((_h = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _h === void 0 ? void 0 : _h.lastname) === (currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.lastname)
                    ? undefined
                    : currentDetailsObj.firstname + ' ' + currentDetailsObj.lastname;
            updateParams.role = ((_j = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _j === void 0 ? void 0 : _j.role) === (roleType === null || roleType === void 0 ? void 0 : roleType.value) ? undefined : roleType === null || roleType === void 0 ? void 0 : roleType.value;
            updateParams.groups = ['*'];
            updateParams.phone =
                ((_k = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _k === void 0 ? void 0 : _k.phone) === (currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.phone) &&
                    ((_m = (_l = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _l === void 0 ? void 0 : _l.countryCode) === null || _m === void 0 ? void 0 : _m.dial_code) === ((_o = currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.countryCode) === null || _o === void 0 ? void 0 : _o.dial_code)
                    ? undefined
                    : ((_p = currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.countryCode) === null || _p === void 0 ? void 0 : _p.dial_code) + currentDetailsObj.phone;
            params._id = ((_q = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _q === void 0 ? void 0 : _q._id) ? (_r = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _r === void 0 ? void 0 : _r._id : selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow._id;
        }
        createNdUpdateData(mode === 'edit' ? updateParams : params, mode);
    };
    var createNdUpdateData = function (params, modeType) {
        var _a, _b;
        var apiService = '';
        setLoading(true);
        if (modeType === 'edit')
            apiService = api.update(url.USERS + '/' + (((_a = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _a === void 0 ? void 0 : _a._id) ? (_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _b === void 0 ? void 0 : _b._id : selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow._id), params, domains.IDM_V1);
        else
            apiService = api.create(url.USERS, params, domains.IDM_V1);
        apiService
            .then(function (resp) {
            var _a;
            resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (((_a = resp.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'success') {
                toast.success(modeType === 'edit' ? toastMessages.userUpdated : toastMessages.userCreated);
                setLoading(false);
                setCurrentDetailsObj({});
                getUsers(searchParams);
                toggle();
            }
        })
            .catch(function (_err) {
            setLoading(false);
        });
    };
    var saveDisabled = function () {
        var flag = true;
        Object.keys(errors).forEach(function (element) {
            if (errors[element])
                flag = false;
        });
        return (!(currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.firstname) ||
            !(currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.lastname) ||
            !(currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.email) ||
            currentDetailsObj.phone.length !== 10 ||
            (mode !== 'edit' && !(currentDetailsObj === null || currentDetailsObj === void 0 ? void 0 : currentDetailsObj.username)) ||
            !flag ||
            !(roleType === null || roleType === void 0 ? void 0 : roleType.value));
    };
    return (_jsxs(React.Fragment, { children: [loading && _jsx(Loader, {}), _jsx("div", { className: "page-content h-100 ".concat(loading ? 'postion-relative mask' : ''), children: _jsxs(Container, { fluid: true, children: [_jsx(BreadCrumb, { title: "Users", backLink: "users" }), _jsx(TableContainer, { loading: loading, data: users, columns: schema, isGlobalFilter: true, totalRecords: totalRecords, addButton: true, addButtonText: 'Add User', searchFilter2: true, tableHeader: 'All Users', searchPlaceHolder: 'Name', customPageSize: 10, onClickSwitch: onStatusChange, handleAddNew: handleAddNew, handleClickView: handleClickView, handleEdit: handleEdit, reader: privs.SA_USER_READER, editor: privs.SA_USER_EDITOR, className: "custom-header-css", divClass: "table-responsive table-card", tableClass: "table-nowrap table-border table-centered align-middle", theadClass: "bg-light text-muted" }), _jsx(DeleteModal, { show: deleteModal, onDeleteClick: handleDeleteConfirmation, onCloseClick: function () {
                                setDeleteModal(false);
                                setSelectedRow('');
                            }, messageText: ((_a = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _a === void 0 ? void 0 : _a.status) === 'active'
                                ? 'Are you sure you want to deactivate this user'
                                : 'Are you sure you want to activate this user', confirmText: ((_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _b === void 0 ? void 0 : _b.status) === 'active' ? 'Yes, Deactivate' : 'Yes, Activate' }), _jsx(OffcanvasModal, { direction: "end", loading: loading, toggle: toggle, open: offCanvasModel, hideSaveButton: mode === 'view', handleCloseClick: toggle, OffcanvasModalID: "usersModal", handleOffcanvasBody: handleOffcanvasBody, modalClassName: "w-40", saveText: mode !== 'save' ? 'Update' : 'Save', offcanvasHeader: mode === 'add' ? 'Add New User' : "".concat(((_c = mode === null || mode === void 0 ? void 0 : mode.charAt(0)) === null || _c === void 0 ? void 0 : _c.toUpperCase()) + (mode === null || mode === void 0 ? void 0 : mode.slice(1)), " User"), saveDisabled: saveDisabled(), handleSaveClick: handleSubmit })] }) })] }));
};
export default Users;
